const PENDING = 'PENDING'
const PAID = 'PAID'
const PROCESSING = 'PROCESSING'
const REJECTED = 'REJECTED'

export default {
  PENDING,
  PAID,
  PROCESSING,
  REJECTED,
}
