<template>
  <div id="payout-list">
    <!-- app drawer -->
    <payout-list-add-new
      v-model="isAddNewPayoutSidebarActive"
      :shop-filter-items="shopFilterItems"
      @refetch-data="fetchList"
      @payout-saved="onPayoutSaved"
      @payout-error="onPayoutError"
      @update:is-add-new-payout-sidebar-active="onUpdateSideBarActive"
    ></payout-list-add-new>
    <!-- list filters -->
    <v-card>
      <v-card-title>Payouts</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="periodTypeModel"
            placeholder="Range Type"
            :items="periodTypeItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            @change="onPeriodTypeChange"
          ></v-select>
        </v-col>

        <v-col
          v-show="showDateRange"
          cols="12"
          sm="4"
          class="pt-0 pb-0"
        >
          <v-menu
            ref="menuDateRangeFilter"
            v-model="menuDateRange"
            :return-value.sync="dateFilterModel"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            hide-details
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Dates Range"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateFilterModel"
              range
              no-title
              scrollable
              hide-details
            >
              <v-btn
                block
                outlined
                small
                dense
                @click="$refs.menuDateRangeFilter.save(dateFilterModel)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="shopFilterModel"
            placeholder="Select Shop"
            :items="shopFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilterModel"
            placeholder="Select Status"
            :items="statusFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-btn
          ref="btnApplyFilters"
          color="primary"
          class="me-3 mb-4"
          :loading="loading"
          @click.prevent="applyFilters"
        >
          Apply Filters
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          type="reset"
          class="me-3 mb-4"
          @click.prevent="resetFilters"
        >
          Reset
        </v-btn>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-text-field
            v-model="searchFilterModel"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            class="mb-4 me-3 searchBy"
            @keyup.enter="onSearch"
            @click:append="onSearch"
          ></v-text-field>

          <v-btn
            v-if="canCreate"
            color="primary"
            class="mb-4 me-3"
            @click.stop="
              isAddNewPayoutSidebarActive = !isAddNewPayoutSidebarActive
            "
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Create Payout</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-0"
            :disabled="payoutItemsLength == 0"
            :loading="loading"
            @click="downloadList"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        ref="payoutsTable"
        :headers="payoutsTableColumns"
        :items="payoutItems"
        :options.sync="options"
        :server-items-length="payoutItemsLength"
        :items-per-page="10"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <!-- UID -->
        <template #[`item.payoutCode`]="{ item }">
          {{ item.payoutCode }}
        </template>

        <!-- Created At  -->
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDateAndTime(item.createdAt) }}</span>
        </template>

        <!-- Amount -->
        <template #[`item.amount`]="{ item }">
          <span
            class="text-capitalize font-weight-semibold text--primary text-right"
          >{{ formatCurrency(item.amount) }} {{ item.currencyCode }}</span>
        </template>

        <template #[`item.sentAmount`]="{ item }">
          <span
            class="text-capitalize font-weight-semibold text--primary text-right"
          >{{ formatCurrency(item.sentAmount) }} {{ item.sentCurrencyCode }}</span>
        </template>

        <!-- Status -->
        <template #[`item.payoutStatus`]="{ item }">
          <v-chip
            small
            :color="resolveStatusVariant(item.payoutStatus.statusCode)"
            :class="`${resolveStatusVariant(
              item.payoutStatus.statusCode
            )}--text`"
            class="status-row v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.payoutStatus.statusCode }}
          </v-chip>
          <div class="notifiedAt">
            {{ formatDateAndTime(item.payoutStatus.statusUpdatedAt) }}
          </div>
        </template>

        <template #[`item.beneficiary`]="{ item }">
          <div>{{ item.beneficiary.email }}</div>
          <div>
            {{ `${item.beneficiary.firstName} ${item.beneficiary.firstName}` }}
          </div>
        </template>
      </v-data-table>
    </v-card>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar.isSnackbarVisible">
      <span v-html="snackbar.snackbarMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar.isSnackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// sidebar
import {
  avatarText,
  formatDate,
  formatDateAndTime,
  formatCurrency,
} from '@core/utils/filter'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiCalendar,
  mdiPublish,
  mdiPublishOff,
  mdiMagnify,
  mdiBankTransferIn,
} from '@mdi/js'
import {
  onMounted, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import storeModule from './storeModule'
import usePayoutList from './usePayoutList'
import PayoutListAddNew from '@/views/processing/payouts/PayoutListAddNew.vue'

export default {
  components: {
    PayoutListAddNew,
  },
  setup() {
    const STORE_MODULE_NAME = 'processing-payouts'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    const {
      payoutsTableColumns,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      periodTypeModel,
      periodTypeItems,
      dateFilterModel,
      menuDateRange,
      payoutItems,
      payoutItemsLength,
      options,
      loading,
      showDateRange,
      snackbar,
      isAddNewPayoutSidebarActive,
      dateRangeText,
      canCreate,
      onPayoutSaved,
      onPayoutError,
      fetchList,
      onSearch,
      applyFilters,
      resetFilters,
      fetchConfig,
      downloadList,
      resolveStatusVariant,
      onPeriodTypeChange,
      onUpdateSideBarActive,
    } = usePayoutList()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    onMounted(() => {
      fetchConfig()
    })

    return {
      payoutItems,
      payoutItemsLength,
      payoutsTableColumns,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      periodTypeModel,
      periodTypeItems,
      dateFilterModel,
      dateRangeText,
      menuDateRange,
      loading,
      options,
      showDateRange,
      isAddNewPayoutSidebarActive,
      onUpdateSideBarActive,
      snackbar,
      canCreate,
      onPayoutSaved,
      onPayoutError,
      avatarText,
      resolveStatusVariant,
      fetchList,
      onSearch,
      applyFilters,
      resetFilters,
      fetchConfig,
      downloadList,
      formatDate,
      formatCurrency,
      formatDateAndTime,
      onPeriodTypeChange,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCalendar,
        mdiPublish,
        mdiPublishOff,
        mdiMagnify,
        mdiBankTransferIn,
      },
    }
  },
}
</script>

<style scoped>
.datePicker {
  margin-top: -5px;
}
.notifiedAt {
  font-size: 11px;
  padding: 5px;
}
.table-row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.searchBy {
  width: 380px;
}

.status-row {
  margin-top: 8px;
}
</style>
